import React, { useLayoutEffect } from 'react';
import {
	CssBaseline,
	createMuiTheme,
	ThemeProvider,
	makeStyles,
	AppBar,
	Toolbar,
	Drawer,
	IconButton,
	Hidden
} from '@material-ui/core';
import Rutas from './rutas.js';
import MenuSuperior from './MenuSuperior.js';
import Meta from '../../core/Meta';
import User from '../../Cds/User';
import Alerts from '../../core/Alerts';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
	},
	alertbox: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
		cursor: 'pointer'
	}
}));

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#ffa726',
		},
			secondary: {
			main: '#ad1457',
		},
		type: 'light' 
	}
});

export default function Main(props) {
	const { window } = props;
	const [pageTitle, setPageTitle] = React.useState('TITULO_DEFAULT_0');
	const [userLoggerIn, setUserLoggerIn] = React.useState(User.isLoggedIn());
	const [alertsMessages, setAlertsMessages] = React.useState([]);
	const [navigationOpened, setNavigationOpened] = React.useState(false);

	const classes = useStyles();

	const Navigation = () => {
		if (userLoggerIn===true) {
			return (
				<nav className={classes.drawer} aria-label="mailbox folders">
					{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
					<Hidden smUp implementation="css">
							<Toolbar />
					</Hidden>
					<Hidden xsDown implementation="css">
							<Toolbar />
					</Hidden>
				</nav>
			);
		}
		return null;
	}

	const handleAlertClick = (alertNumber) => {
		Alerts.removeAlert(alertNumber);
	}

	const handleDrawerToggle = () => {
		setNavigationOpened(!navigationOpened);
	}

	const metaChangeHandler = (objetos) => {
		setPageTitle(objetos.Meta.getPageTitle());
	}

	const userChangeHandler = (objetos) => {
		objetos.setUserLoggerIn(objetos.User.isLoggedIn());
	}

	const alertUpdateHandler = (objetos) => {
		objetos.setAlertsMessages(objetos.Alerts.getAlerts());
	}

	useLayoutEffect(() => {
		User.registerCallback(userChangeHandler,{User,setUserLoggerIn});
		Alerts.registerCallback(alertUpdateHandler,{Alerts,setAlertsMessages});
		Meta.registerCallback(metaChangeHandler,{Meta});
	}, []);

	const ShowAlerts = () => {
		return (
			<div className={classes.alertbox}>
				{alertsMessages.map((message,number)=> {
					return (
						<MuiAlert onClick={() => {handleAlertClick(number)}} key={'alert'+number} variant="filled" severity={message.type}>{message.message}</MuiAlert>
					);
				})}
			</div>
		);
	}

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				<MenuSuperior />
				<Navigation />
				<main className={classes.content}>
					<ShowAlerts />
					<Rutas />
				</main>
			</div>
		</ThemeProvider>
	);
}