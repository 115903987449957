import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import VideoStreamMerger from 'video-stream-merger';
import * as Tone from 'tone';
//temporales
import video from '../vid/example1.mp4';

const useStyles = makeStyles((theme) => ({
	'content': {
		width: '100%',
		height: '100%'
	},
	'playerwrapper': {
		border: '3px solid green',
		position: 'relative',
		height: 'clamp(120px, calc(100vw * 0.75), 480px)'
	},
	'reactplayer': {
		position: 'absolute',
		top: 0,
		left: 0,
		border: '1px solid red'
	},
	'videooriginal': {
		display: 'none'
	}
}));
/*
.player-wrapper {
  width: auto; // Reset width
  height: auto; // Reset height
}
.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}
.react-player > div {
  position: absolute; // Scaling will occur since parent is relative now
}
*/

const audio = new Tone.Player();
const audio_pitch = new Tone.PitchShift().toDestination();
var currentUrl = null;

const AudioPlay = (fileUrl,pitch) => {
	if (currentUrl!==fileUrl) {
		currentUrl = fileUrl;
		audio.load(fileUrl).then(() => {
			audio.start();
			audio.volume.value = -24;
			console.log('get() ',audio.get());
		}).catch((error) => {
			console.log(error);
		});
		audio.disconnect();
		audio.connect(audio_pitch);
	}
	audio_pitch.pitch = pitch;

	//audio.toDestination();
	//var cambio_tono = new Tone.PitchShift({ pitch: audioTono }).toDestination();

}

export default function Player(props) {
	const classes = useStyles();
	const [videoToPlay, setvideoToPlayideoToPlay] = React.useState(null);
	const [audioTono, setAudioTono] = React.useState(0);
	const [videoUrlToPlay, setvideoUrlToPlayideoToPlay] = React.useState(process.env.PUBLIC_URL+'/'+props.match.params.video);

	useEffect(() => {
		return () => {
		}
	}, []);

//	videoOriginal.addEventListener("loadedmetadata", function (e) {
//		mezcladora.setOutputSize(this.videoWidth, this.videoHeight);
//	}, false );

	/*
	var audio = new Tone.Player(process.env.PUBLIC_URL+"/example1.mp4",() => {
		audio.start();
		audio.volume.value = -24;
		//audio.mute = true;
	})
	*/

	AudioPlay(videoUrlToPlay,audioTono);

	const reproducir = (e,videoUrl,tono) => {
		e.preventDefault();
		setvideoUrlToPlayideoToPlay(process.env.PUBLIC_URL+'/'+videoUrl);
		setAudioTono(tono);
	}

	return (
		<div className={classes.content}>
			<div className={classes.playerwrapper}>
				<div>
					<ReactPlayer
						width="100%"
						height="100%"
						className={classes.reactplayer}
						url={videoUrlToPlay}
						playing={true}
						controls={false}
						volume={0}
						config={
							{ 
								file: { 
								attributes: {
										onContextMenu: e => e.preventDefault(),
										controlsList: 'nodownload noremoteplayback',
									} 
								} 
							}
						} />
				</div>
			</div>
			<ul>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example1.mp4',-3) }}>Reproducir Video1 con Tono -3</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example1.mp4',-2) }}>Reproducir Video1 con Tono -2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example1.mp4',-1) }}>Reproducir Video1 con Tono -1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example1.mp4',0) }}>Reproducir Video1 con Tono 0</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example1.mp4',1) }}>Reproducir Video1 con Tono 1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example1.mp4',2) }}>Reproducir Video1 con Tono 2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example1.mp4',3) }}>Reproducir Video1 con Tono 3</a></li>
				<li>--------------</li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example2.mp4',-3) }}>Reproducir Video1 con Tono -3</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example2.mp4',-2) }}>Reproducir Video1 con Tono -2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example2.mp4',-1) }}>Reproducir Video1 con Tono -1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example2.mp4',0) }}>Reproducir Video1 con Tono 0</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example2.mp4',1) }}>Reproducir Video1 con Tono 1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example2.mp4',2) }}>Reproducir Video1 con Tono 2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example2.mp4',3) }}>Reproducir Video1 con Tono 3</a></li>
				<li>--------------</li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example3.mp4',-3) }}>Reproducir Video1 con Tono -3</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example3.mp4',-2) }}>Reproducir Video1 con Tono -2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example3.mp4',-1) }}>Reproducir Video1 con Tono -1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example3.mp4',0) }}>Reproducir Video1 con Tono 0</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example3.mp4',1) }}>Reproducir Video1 con Tono 1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example3.mp4',2) }}>Reproducir Video1 con Tono 2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example3.mp4',3) }}>Reproducir Video1 con Tono 3</a></li>
				<li>--------------</li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example4.mp4',-3) }}>Reproducir Video1 con Tono -3</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example4.mp4',-2) }}>Reproducir Video1 con Tono -2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example4.mp4',-1) }}>Reproducir Video1 con Tono -1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example4.mp4',0) }}>Reproducir Video1 con Tono 0</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example4.mp4',1) }}>Reproducir Video1 con Tono 1</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example4.mp4',2) }}>Reproducir Video1 con Tono 2</a></li>
				<li><a href="/#" onClick={(e) => { reproducir(e,'example4.mp4',3) }}>Reproducir Video1 con Tono 3</a></li>
			</ul>
		</div>
	);
}