

class Alerts {
	constructor() {
		this.alerts = [];
		this.callbacks = [];
	}
	getAlerts() {
		return [...this.alerts];
	}
	newAlert(type,message) {
		this.alerts.push({
			'type': type,
			'message': message
		});
		this.triggerCallbacks();
	}
	removeAlert(alertIndex) {
		this.alerts.splice(alertIndex,1);
		this.triggerCallbacks();
	}
	error(message) {
		this.newAlert('error',message);
	}
	registerCallback(callbackFunctionReceived,callbackObjectReceived) {
		this.callbacks.push({
			callbackFunction: callbackFunctionReceived,
			callbackObject: callbackObjectReceived
		});
	}
	triggerCallbacks() {
		this.callbacks.forEach((registeredCallback) => {
			registeredCallback.callbackFunction(registeredCallback.callbackObject);
		});
	}
}
export default new Alerts();