import React, { useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import User from '../../Cds/User';
import Meta from '../../core/Meta';
import Http403 from './errors/Http403.js';

export default function RenderRoute(props) {
	//const props = {...rest};

	// utilizamos el useEffect para actualizar el estado hasta despues de terminar de renderear el objeto
	// utilizamos el useLayoutEffect para actualizar el estado antes de renderizar pero antes de hacer commit al navegador
	useLayoutEffect(() => {
		Meta.setPageTitle(props.title,1);
	});

	const Componente = props.component;

	if (typeof props.access!=='undefined') {
		if (props.match.path==='/login'&&!User.isLoggedIn()) {
			return <Componente {...props} />;
		} else if (!User.isLoggedIn()) {
			return <Redirect {...props} push={true} to="/login" />; //cambiar a "return <Redirect {...props} push={true} to="/" />;" login al finalizar
		} else if (User.checkPermission(props.access)===true) {
			return <Componente {...props} />;
		} else {
			return <Http403 />;
		}
	} else {
		return <Componente {...props} />;
	}
}