import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactPlayer from 'react-player'
//import BarraSuperiorUsuario from './BarraSuperiorUsuario'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
//import example1 from '../vid/example1.mp4'
import example2 from '../vid/example2.mp4'
import example3 from '../vid/example3.mp4'
import { createBrowserHistory } from 'history';
import ButtonBase from '@material-ui/core/ButtonBase';
//import josejose from '../img/josejose.jpg';
import VideoStreamMerger from 'video-stream-merger';
import * as Tone from 'tone';

//import video from '../vid/video.mp4';
//import audio from '../vid/audio.ogg';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const images = [
  {
	url: './img/josejose',
	title: 'El Triste',
	width: '25%',
  },
  {
	url: {example2},
	title: 'Lo Pasado',
	width: '25%',
  },
  {
	url: {example3},
	title: 'Lo Que',
	width: '25%',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
	maxWidth: 1000,
  },
  media: {
	height: 0,
	paddingTop: '20.25%', // 16:9
  },
  expand: {
	transform: 'rotate(0deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
	  duration: theme.transitions.duration.shortest,
	}),
  },
  expandOpen: {
	transform: 'rotate(180deg)',
  },
  avatar: {
	backgroundColor: red[500],
  },
  paper: {
	padding: theme.spacing(3),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  },
  container: {
	paddingTop: theme.spacing(4),
	paddingBottom: theme.spacing(4),
  },
  image: {
	position: 'relative',
	height: 200,
	[theme.breakpoints.down('xs')]: {
	  width: '100%',
	  height: 100,
	},
	'&:hover, &$focusVisible': {
	  zIndex: 1,
	  '& $imageBackdrop': {
		opacity: 0.15,
	  },
	  '& $imageMarked': {
		opacity: 0,
	  },
	  '& $imageTitle': {
		border: '4px solid currentColor',
	  },
	},
  },
  focusVisible: {},
  imageButton: {
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.common.white,
  },
  imageSrc: {
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundColor: theme.palette.common.black,
	opacity: 0.4,
	transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
	position: 'relative',
	padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
	height: 3,
	width: 18,
	backgroundColor: theme.palette.common.white,
	position: 'absolute',
	bottom: -2,
	left: 'calc(50% - 9px)',
	transition: theme.transitions.create('opacity'),
  },
}));

export default function Cancion() {
	const classes = useStyles();

	var merger = new VideoStreamMerger();

	var videoElement = document.createElement('video');
	var audioElement = document.createElement('audio');

	videoElement.muted = true;
	audioElement.muted = true;

	videoElement.src = process.env.PUBLIC_URL+"/video.mp4";
	audioElement.src = process.env.PUBLIC_URL+"/audio.ogg";

	videoElement.autoplay = true;
	audioElement.autoplay = true;

	videoElement.loop = false;
	//videoElement.play();
	//audioElement.play();

	/*
	useEffect(() => {
		return () => {
			videoElement.pause();
			audioElement.pause();
		}
	}, []);
	*/

	videoElement.addEventListener("loadedmetadata", function (e) {
		merger.setOutputSize(this.videoWidth, this.videoHeight);
	}, false );

	var audio = new Tone.Player(process.env.PUBLIC_URL+"/audio.ogg",() => {
		//audio.start();
		audio.volume.value = -24;
		//audio.mute = true;
	});

	audio.toDestination();

	var pitch_shift = new Tone.PitchShift({
		pitch:  '0'
	}).toDestination();

	audio.disconnect();
	audio.connect(pitch_shift);

	merger.addMediaElement('mp4', videoElement, {
		x: 0,
		y: 0,
		mute: true
	});
	merger.addStream('audio', audio, {
		x: 0,
		y: 0,
		mute: true
	});

	merger.start();

	return (
		<React.Fragment>
			<CssBaseline />

			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={5}>
					<Grid item xs={12} sm={7}>
						<Card className={classes.paper}>
						<CardHeader
							action={
							<IconButton aria-label="settings">
								<MoreVertIcon />
							</IconButton>
							}
							title="Karaokanta"
							align="Left"
						/>
						<CardMedia>
							<ReactPlayer
								url={merger.result}
								playing={true}
								controls={true}
								volume={0.3}
								config={{ 
									file: { 
									attributes: {
										onContextMenu: e => e.preventDefault(),
										controlsList: 'nodownload noremoteplayback',
									} 
								} 
								}} />
						</CardMedia>
						<CardContent>
							<p>Bienvenido</p>
							<p>Bienvenido</p>
							<p>Bienvenido</p>
						<div className={classes.root}>
							{images.map((image) => (
						<ButtonBase
						focusRipple
							key={image.title}
						className={classes.image}
						focusVisibleClassName={classes.focusVisible}
						style={{
							width: image.width,
						}}
						>
						<span
							className={classes.imageSrc}
							style={{
							backgroundImage: `url(${image.url})`,
							}}
						/>
						<span className={classes.imageBackdrop} />
						<span className={classes.imageButton}>
							<Typography
							component="span"
							variant="subtitle1"
							color="inherit"
							className={classes.imageTitle}
							>
							{image.title}
							<span className={classes.imageMarked} />
							</Typography>
						</span>
						</ButtonBase>
					))}
					</div>
						</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={5}>
					<Card className={classes.paper}>
						<CardHeader
							action={
							<IconButton aria-label="settings">
								<MoreVertIcon />
							</IconButton>
							}
							title="Videos Relacionados"
						/>
						<CardMedia>
							{/*No hay nada aqui... de momento*/}
						</CardMedia>
						<CardContent>
							<Typography  color="textSecondary" >
							aqui van videos
							</Typography>
						</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}