//import React from 'react';
import axiosclient from '../AxiosClient';
import {getts} from '../Tomalish';
import jwt from 'jsonwebtoken';

class User {
	constructor() {
		this.loadUser();
		if (this.ID===null) { this.user = 0; }
		if (this.logins===null) { this.logins = 0; }
		if (this.logouts===null) { this.logouts = 0; }
		if (this.name===null) { this.name = 'Unknown'; }
		if (this.username===null) { this.username = 'Anonymous'; }
		if (this.loggedin===null) { this.loggedin = false; }
		if (this.register_ip===null) { this.register_ip = ''; }
		if (this.ts===null) { this.ts = getts(); }
		if (this.accessToken===null) { this.accessToken = ''; }
		this.callbacks = [];

		if ((this.ts+3600)<getts()) {
			if (this.token!=='') {
				const axiosConfig = {
					headers: { Authorization: `Bearer ${this.token}` }
				};
				axiosclient.get('/token',axiosConfig)
				.then(res => {
					if (res!==undefined) {
						const data = res.data;
						if (data.loggedin) {
							for (const [key, value] of Object.entries(data.User)) {
								this.[key] = value;
							}
							this.loggedin = data.loggedin;
							this.token = data.token;
							this.saveUser();
						} else {
							this.token = '';
							this.loggedin = false;
							this.saveUser();
						}
					}
				});
			}
		}
		this.saveUser();
	}

	loadUser() {
		var toLoad = JSON.parse(localStorage.getItem('User'));
		if (toLoad==null) {
			return false;
		}
		if (toLoad.accessToken!==null) {
			try {
				jwt.verify(toLoad.accessToken,toLoad.accessPublicKey);
			} catch  {
				return false;
			}
		}
		for (const [key, value] of Object.entries(toLoad)) {
			this.[key] = value;
		}
		return true;
	}

	saveUser() {
		var toSave = {};
		for (const [key, value] of Object.entries(this)) {
			if (key!=='callbacks') {
				toSave.[key] = value;
			}
		}
		toSave.accessToken = this.accessToken;
		toSave.accessPublicKey = this.accessPublicKey;
		localStorage.setItem('User',JSON.stringify(toSave));
		this.callbacks.forEach((registeredCallback) => {
			registeredCallback.callbackFunction(registeredCallback.callbackObject);
		});
	}

	registerCallback(callbackFunctionReceived,callbackObjectReceived) {
		this.callbacks.push({
			callbackFunction: callbackFunctionReceived,
			callbackObject: callbackObjectReceived
		});
	}

	isLoggedIn() {
		if (this.authentication) {
			return true;
		} else {
			return false;
		}
	}

	isRegisteredIn() {
		if(this.authentication) {
			return true;
		} else {
			return false;
		}
	}

	checkPermission(level) {
		if (this.User.access.includes(level)) {
			return true;
		} else {
			return false;
		}
	}

	registerUser(props,callback) {
		axiosclient.post('/registro', {
			username: props.username,
			password: props.password,
			email: props.email,
		}).then(returned => {
			const data = returned.data;
			if (data.authentication) {
				for (const [key, value] of Object.entries(data.User)) {
					this.[key] = value;
				}
				this.authentication = data.authentication;
				this.accessToken = data.accessToken;
				this.accessPublicKey = data.accessPublicKey;
				this.saveUser();
				return callback(true);
			} else {
				this.authentication = false;
				this.saveUser();
				return callback(false);
			}
		});
	}

	loginUserPassword(props,callback) {
		axiosclient.post('/login', {
			username: props.username,
			password: props.password
		}).then(returned => {
			const data = returned.data;
			if (data.authentication) {
				for (const [key, value] of Object.entries(data.User)) {
					this.[key] = value;
				}
				this.authentication = data.authentication;
				this.accessToken = data.accessToken;
				this.accessPublicKey = data.accessPublicKey;
				this.saveUser();
				return callback(true);
			} else {
				this.authentication = false;
				this.saveUser();
				return callback(false);
			}
		});
	}

	getAccessToken() {
		if (this.accessToken===null) {
			return false;
		} else {
			return this.accessToken;
		}
	}

	logout(callback) {
		this.user = 0;
		this.logins = 0;
		this.logouts = 0;
		this.name = 'Unknown';
		this.username = 'Anonymous';
		this.loggedin = false;
		this.register_ip = '';
		this.ts = getts();
		this.loggedin = false;
		this.saveUser();
		return callback(false);
	}
}

export default new User();