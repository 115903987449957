
class Meta {
	constructor() {
		this.pageTitle = 'TITULO_NO_DEFINIDO';
		this.pageTitlePriority = 0;
		this.callbacks = [];
	}
	getPageTitle() {
		return this.pageTitle;
	}
	setPageTitle(newPageTitle,priority=0) {
		if (priority>=this.pageTitlePriority&&newPageTitle!==this.pageTitle) {
			this.pageTitle = newPageTitle;
			this.pageTitlePriority = priority;
			this.triggerCallbacks();
		}
	}
	registerCallback(callbackFunctionReceived,callbackObjectReceived) {
		this.callbacks.push({
			callbackFunction: callbackFunctionReceived,
			callbackObject: callbackObjectReceived
		});
		this.triggerCallbacks();
	}
	triggerCallbacks() {
		this.callbacks.forEach((registeredCallback) => {
			registeredCallback.callbackFunction(registeredCallback.callbackObject);
		});
	}
}

export default new Meta();