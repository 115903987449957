import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

import RenderRoute from './RenderRoute';
import BarraSuperiorGuest from '../../tmp/BarraSuperiorGuest'
import BarraSuperiorUsuario from '../../tmp/BarraSuperiorUsuario'
import Main from '../../tmp/Main'
import Suscripciones from '../../views/cds/suscripciones'
import Registro from '../../views/cds/registro.js';
import Login from '../../views/cds/login.js';
import Start from '../../views/Start.js';
import Player from '../../views/Player.js';
import Http404 from './errors/Http404';

export default function Rutas() {
	return(
		<Router>
			<Switch>
				<Route
					path="/tmp/Main"
					component={({...rest}) => <RenderRoute 
					{...rest}
					access="guest"
					title="TMP Main"
					component={Main}
					/>}
				/>
				<Route
					path="/tmp/BarraSuperiorGuest"
					component={({...rest}) => <RenderRoute 
					{...rest}
					access="guest"
					title="TMP BarraSuperiorGuest"
					component={BarraSuperiorGuest}
					/>}
				/>
				<Route
					path="/tmp/BarraSuperiorUsuario"
					component={({...rest}) => <RenderRoute 
					{...rest}
					access="guest"
					title="TMP BarraSuperiorUsuario"
					component={BarraSuperiorUsuario}
					/>}
				/>

				
				<Route
					path="/Player/:video"
					component={({...rest}) => <RenderRoute 
					{...rest}
					access="guest"
					title="TMP Player"
					component={Player}
					/>}
				/>


				
				<Route
					exact
					path="/"
					component={({...rest}) => <RenderRoute 
					{...rest}
					access="guest"
					title="Inicio"
					component={Start}
					/>}
				/>
				<Route
					exact
					path="/login"
					render={(rest) => <RenderRoute
						{...rest}
						access="guestonly"
						title="Inicio de Sesión"
						component={Login}
					/>}
				/>
				<Route
					path="/registro"
					component={({...rest}) => <RenderRoute 
					{...rest}
					access="guest"
					title="Registro"
					component={Registro}
					/>}
				/>
				<Route
					path="/suscripciones"
					component={({...rest}) => <RenderRoute 
					{...rest}
					access="guest"
					title="Suscripcion"
					component={Suscripciones}
					/>}
				/>
				<Route 
					component={({...rest}) => <RenderRoute 
					{...rest}
					title="404: Not found"
					component={Http404}
					/>}
				/>
			</Switch>
		</Router>
	);
}