
const handleChange = (event,formData,setFormData) => {
	var newValue = {...formData};
	if (event.target.type==='checkbox') {
		newValue[event.target.name] = event.target.checked;
	} else {
		newValue[event.target.name] = event.target.value;
	}
	setFormData(newValue);
}

export default handleChange;