import axios from "axios";
import User from './Cds/User';

class Request {
    constructor() {
		this.baseURL = 'https://karaokanta.tomalish.net/api';
		this.isRefreshing = false;
		this.failedRequests = [];
		//this.tokenService = new TokenService();
		this.client = axios.create({
				baseURL: this.baseURL,
				headers: {
					appID: 8,
					version: 0
				},
			});
		this.beforeRequest = this.beforeRequest.bind(this);
		this.onRequestFailure = this.onRequestFailure.bind(this);
		this.processQueue = this.processQueue.bind(this);
		this.client.interceptors.request.use(this.beforeRequest);
		this.client.interceptors.response.use(this.onRequestSuccess,this.onRequestFailure);
	}

	beforeRequest(request) {
		if (User.getAccessToken()!==false) {
			request.headers.Authorization = `Bearer ${User.getAccessToken()}`;
		}
		return request;
	}

	static onRequestSuccess(response) {
		return response;
	}

	async onRequestFailure(response) {
		return response.response;
	}

	processQueue(error, token = null) {
		this.failedRequests.forEach((prom) => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve(token);
			}
		});
		this.failedRequests = [];
	}
}

const request = new Request();

export default request.client;