import React from 'react';
import { Link } from "react-router-dom";
import { Container } from '@material-ui/core';
import Player from './Player';

export default function Start() {
	return(
		<Container>
			<p>Reproductor<Link to="/Player/example4.mp4">*/Player/videoalgo.html</Link></p>
			<p>Login<Link to="/login">*/login</Link></p>
			<p>Suscripcion<Link to="/suscripciones">*/Suscripciones</Link></p>
			<p>Registro<Link to="/registro">*/Registro</Link></p>
			<p>BarraSuperior Invitado<Link to="/tmp/BarraSuperiorGuest">*/Cds/BarraSuperiorGuest</Link></p>
			<p>BarraSuperior Usuario<Link to="/tmp/BarraSuperiorUsuario">*/Cds/BarraSuperiorUsuario</Link></p>
		</Container>
	);
}