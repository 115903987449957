import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Karaokanta from '../../img/Karaokanta.png'
import User from '../../Cds/User.js';
import Alerts from '../../core/Alerts.js';
import handleChange from '../../core/handlechange.js';
import { LinearProgress } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.tomalish.net/">
        Tomalish.Networks
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function Login(props) {
  const classes = useStyles();
  const [formData, setFormData] = React.useState({
    "user":"test@test.test",
    "pass":"contraseña",
    "remember": false
  });
  const [loginButtonBusy, setLoginButtonBussy] = React.useState(false);

  const handleSubmit = (event, formData,setFormData,props) => {
    event.preventDefault();
    setLoginButtonBussy(true);
    User.loginUserPassword({
        username: formData.user,
        password: formData.pass
    }, (result) =>{
        console.log('result', result);
        if(result){
          console.log('props',props);
          props.history.goBack();
        }else {
          Alerts.error('El usuario y/o contraseña ingresados son incorrectos');
        }
        setLoginButtonBussy(false);
    });
    var newValue= {...formData};
    newValue.pass = '';
    setFormData(newValue);
  }

  const LoginSubmit = () => {
    if(loginButtonBusy) {
        return (
            <LinearProgress/>
        );
    }else {
        return (
            <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
                Entrar      
            </Button>
        );
    }
  }
  
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar src={Karaokanta} className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ¡Bienvenido!
          </Typography>
          <form className={classes.form} onSubmit={(e) => {handleSubmit(e,formData,setFormData,props)}}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="Ingresa Tu Correo"
              name="user"
              autoComplete="email"
              autoFocus
              value={formData.user}
              onChange={(e) => handleChange(e, formData, setFormData)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="pass"
              label="Contraseña"
              type="password"
              id="pass"
              value={formData.pass}
              autoComplete="current-password"
              onChange={(e) => handleChange(e, formData, setFormData)}
            />
            <FormControlLabel
              control={<Checkbox name="remember" value="true" checked={formData.remember} color="primary" />}
              label="No olvidar"
              onChange={(e) => handleChange(e,formData,setFormData)}
            />

            <LoginSubmit/>
            
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Olvide mi contraseña
                </Link>
              </Grid>
              <Grid item>
                <Link href="/registro" variant="body2">
                  {"¿No tienes cuenta? Registrate"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
    );
}